import { NextPageWithLayout } from "@bptypes/layout";
import Error, { ErrorTypes } from "@components/pages/error/Error";
import { MainLayout } from "@layouts/index";
import { GetStaticPropsContext } from "next";
import { serverSideTranslations } from "next-i18next/serverSideTranslations";
import { DEFAULT_LOCALE } from "../lib/constants";

const Error404Page: NextPageWithLayout = () => {
	return (
		<Error errorType={ErrorTypes.NotFound} />
	);
};

Error404Page.getLayout = (page) => {
	return (
		<MainLayout title="404 Error" hasDesktopSidebar={false}>
			{page}
		</MainLayout>
	);
};

export default Error404Page;

export const getStaticProps = async (ctx: GetStaticPropsContext) => {
	return {
		props: {
			...(await serverSideTranslations(ctx.locale ?? DEFAULT_LOCALE, ["translation"])),
		},
	};
};
